import {Sidebar,Nav,Button,Box,Text,Image,Menu} from "grommet";
import { Projects,Cafeteria,Add,DocumentImage} from "grommet-icons";
import { ReactComponent as Qr } from './qr.svg';

const SideBarPage=(props)=>{
    
   const coloreSfondo="#605555";
   
    function ordineAlfabetico(){
        let elencoPiattiApp=[...props.elencoPiatti];
        elencoPiattiApp.sort((a, b) => +(a.data().nome > b.data().nome) || -(a.data().nome < b.data().nome));
        sessionStorage.setItem("ordine","alfabetico");
        props.setElencoPiatti(elencoPiattiApp);  
    }

    function ordineCategoria(){
        let elencoPiattiApp= [...props.elencoPiatti];
        elencoPiattiApp.sort((a, b) => +(a.data().categoria > b.data().categoria ) || -(a.data().categoria < b.data().categoria));
        elencoPiattiApp.sort(function(a,b){
            if(a.data().categoria===b.data().categoria){
                return a.data().indice - b.data().indice;
            }
        })
        sessionStorage.setItem("ordine","categoria");
        props.setElencoPiatti(elencoPiattiApp);
    }

    return(
        <Sidebar elevation="large" width={(props.size==="small")?"medium":"small" } height="large" background={coloreSfondo}  responsive={true}  pad={{ left: 'medium', right: 'medium', vertical: 'medium' }}
        header={
           <Box gap="xlarge">
            {props.url!==""?
                <Box width="xlarge" background="white" round="large" >
                    <Image fill src={props.url}/>
                </Box>
                :
                <Box/>
            }
            <Nav align="start" gap="large"  responsive={true}>
            <Button plain icon={<Projects />} hoverIndicator label="Categorie" 
                onClick={()=>props.setPagina("Categoria")}/>
            <Button plain icon={<Cafeteria />} hoverIndicator label="Piatti"
                onClick={()=>props.setPagina("Piatto")}/> 
            <Button plain icon={<Add/>} hoverIndicator label="Aggiungi"
                onClick={()=>props.setShowLayer(true)}/>
            <Button plain icon={<DocumentImage/>} hoverIndicator label="Logo"
                onClick={()=>props.setPagina("Logo")}/>
            <Button plain icon={<Qr color="white"/>} hoverIndicator label="QR Code"
                onClick={()=>props.setPagina("Qr-Code")}/>
            </Nav> 
        </Box>
          }  
        footer={
            <Box gap="xlarge">
               {props.pagina==="Piatto"?
                <Menu
                    label="Ordine"
                    items={[
                        { label: 'Alfabetico', onClick: () => {ordineAlfabetico()} },
                        { label: 'Categoria', onClick: () => {ordineCategoria()} },
                    ]}
                    />
                :
                null
                }
                <Text size="small" >Powered by <a href="https://wegloo.it/">Wegloo</a></Text>
            </Box>
        }
        >
        </Sidebar>
    )
}
export default SideBarPage;
