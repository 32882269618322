import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LoginPage from './page/Login';
import DashBoard from './page/DashBoard';

const Routes = () => (
    <BrowserRouter>
         <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route exact path="/dashboard" component={DashBoard} />
         </Switch>
    </BrowserRouter>
);

export default Routes;