import { Text,Button,Box,Layer,FileInput} from "grommet";
import { getStorage, ref, uploadBytes} from "firebase/storage";

const LayerLogo=(props)=>{
    console.log(props.email);
    return(
        <Layer>
            <Box pad="medium" gap="large" align="center">
            <Text style={{fontSize:'18px'}}>Carica logo del locale: </Text>
                            <FileInput
                                multiple={{
                                  max: 1,
                                }}
                                name="file"
                                onChange={event => {
                                  const fileList = event.target.files;  
                                  try{ 
                                   const filePicked=fileList[0];
                                   const storage = getStorage(); //Inizializzo firebase storage
                                   let fileName; 
                                   fileName="logo_"+props.email; //Creo il nome del file   
                                   const image=ref(storage,"loghi/"+fileName); //Creo un riferimento come richiesto da firebase
                                   uploadBytes(image, filePicked).then((snapshot) => { //Uso la funzione uploadBytes per caricarlo
                                     console.log('File caricato');
                                   });    
                                   alert("Logo caricato correttamente"); 
                                   props.setRendering(true);
                                   props.setPagina("Categoria");
                                    }catch(e){
                                        console.log("Errore nel caricamento");
                                        alert("Errore nel caricamento del logo "+e);
                                    }
                                }}/>
                <Button label="Chiudi" onClick={()=>props.setPagina("Categoria")} style={{width:'130px',fontSize:'15px',background:'red',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderColor:'red'}} /> 
            </Box>
        </Layer>
    )
}

export default LayerLogo;
  