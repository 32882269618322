import {Button,Box,Layer,Text} from "grommet";
import html2canvas from "html2canvas";

const indirizzoSitoMenu="";

const LayerQrCode=(props)=>{

      const logo = require('./QrCode.png').default; 

      function generaImmagine(){
        const inputMappa = document.getElementById('QrCode');
        html2canvas((inputMappa),{
          width: inputMappa.width,
          height: inputMappa.scrollHeight,
          useCORS:true,
        }).then((canvas) => {
            var image = canvas.toDataURL('image/png', 1.0);
            salva(image,"QrCode bar italia.png");
          }); 
      }

  /**
   * 
   * @param {*} blob  immagine canvas 
   * @param {*} fileName  nome del file da scaricare
   */
  const salva = (blob, fileName) =>{
    var elem = window.document.createElement('a');
    elem.href = blob
    elem.download = fileName;
    elem.style = 'display:none;';
    (document.body || document.documentElement).appendChild(elem);
    if (typeof elem.click === 'function') {
      elem.click();
    } else {
      elem.target = '_blank';
      elem.dispatchEvent(new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      }));
    }
    URL.revokeObjectURL(elem.href);
    elem.remove()
  }

    return(
        <Layer>
            <Box pad="medium" gap="large"  align="center">
                <Text>QR Code menù:</Text>
                <Box height="300px" width="300px" id="QrCode">
                    <img src={logo}/>
                </Box>
                {/*<QRCode onClick={DownloadQRCode} id="QrCode" value={indirizzoSitoMenu}/>*/}
                <Box direction="row" gap="large">
                    <Button label="Chiudi" onClick={()=>props.setPagina("Categoria")} style={{width:'130px',fontSize:'15px',background:'red',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderColor:'red'}} /> 
                    <Button label="Scarica" onClick={generaImmagine} style={{width:'130px',fontSize:'15px',background:'#009245',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderColor:'#009245'}} />  
                </Box>
            </Box>
        </Layer>
    )
}

export default LayerQrCode;