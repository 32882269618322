import { initializeApp } from "firebase/app";
import {Grommet} from "grommet";
import Routes from "./routs";
import DocumentMeta from 'react-document-meta';
import {Helmet} from "react-helmet";

const sito="https://menubaritaliagestionale.firebaseapp.com/";

const firebaseConfig = {
  apiKey: "AIzaSyAefhkI-0ZhyeikS1v6_8bJ7rcyRsNgaok",
  authDomain: "menudigitale-2c9a2.firebaseapp.com",
  projectId: "menudigitale-2c9a2",
  storageBucket: "menudigitale-2c9a2.appspot.com",
  messagingSenderId: "199922579006",
  appId: "1:199922579006:web:f3f8f9d125da9b4a8a436f",
  measurementId: "G-QMZXBN68MX"
};

const meta = {
  title: 'Gestionale Bar Italia',
  description: 'Applicazione gestionale del menù di Bar italia',
  canonical: sito,
  meta: {
    charset: 'utf-8',
  }
};

initializeApp(firebaseConfig);

function App() {
  return (
    <DocumentMeta {...meta}>
       <Helmet>
        <meta charSet="utf-8" />
        <title>Gestionale Bar Italia</title>
        <link rel="canonical" href={sito} />
      </Helmet>
      <Grommet>
          <Routes/>
      </Grommet>
    </DocumentMeta>
  );
}

export default App;
