import React from "react";
import { Grommet,Text,Button,Box,ResponsiveContext,Layer,TextInput,FormField,Select,TextArea,List,Menu,FileInput} from "grommet";
import { getFirestore, collection,deleteDoc,doc ,where,query,getDocs,addDoc,updateDoc} from "@firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";
import LayerLogo from "./LayerLogo";
import SideBarPage from "./SiderPage";
import LayerQrCode from "./LayerQrCode";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";


const DashBoard=(props)=>{

    const [pagina,setPagina]=React.useState("Categoria"); //Serve per cambiare dinamicamente la pagina da visualizzare
    const [elencoCategorie,setElencoCategorie]=React.useState([]); //Contiene tutti i dati delle categorie presenti su firestore
    const [elencoPiatti,setElencoPiatti]=React.useState([]); //Contiene tutti i dati dei piatti presenti su firestore
    const [showLayer,setShowLayer]=React.useState(false); //Utilizzato per mostrare il layer di aggiunta o aggiornamento di un elemento, viene utilizzato anche per renderizzare
    const [dropDownCategoria,setDropDownCategoria]=React.useState(""); //DropDown menu che contiene tutte le categorie presenti a sistema, utilizzato nella creazione o nell'aggiornamento di un nuovo piatto
                                                                    //per assegnarli una categoria
    const elencoTipologie=["Categoria","Piatto"];  
    const [dropDownTipo,setDropDownTipo]=React.useState(""); //DropDown menu utilizzato nel layer per capire se sto aggiungengo un piatto o una categoria e mostrare i relativi campi
    const [nome,SetNome]=React.useState("");    //Nome categoria o piatto
    const [descrizione,setDescrizione]=React.useState("");  //Descrizione di un piatto
    const [aggiorna,setAggiorna]=React.useState(false); //Utilizzato per mostrare nel layer le informazioni dell elemento selezionato e permettere di inovocare la funzione Aggiorna() al posto di Aggiungi()
    const [prezzo,SetPrezzo]=React.useState("€");   //Prezzo di un piatto
    const [indexAggiorna,setIndexAggiorna]=React.useState(); //Salva l'indice dell elemento cliccato, serve per trovare nell array il documento relativo all'elemento
    const [urlImmagine,setUrlImmagine]=React.useState(""); //Utilizzato per conterne l'url del logo del locale scaricato dallo storage
    const [indiceCategoria,setIndiceCategoria]=React.useState(""); //Indice utilizzato per permettere all'utente di ordinare le categorie a loro piacimento
    const [email,setEmail]=React.useState(""); //Contiene l'indirizzo email letto da sessionStorage in avvio
    const [rendering,setRendering]=React.useState(true); //Utilizzata per capire se è stato aggiunto/modificato un elemento e rendirizzare i dati di conseguenza
    const [nomeCategoriaApp,setNomeCategoriaApp]=React.useState(); //Utilizzata come appoggio per salvare il nome della vecchia categoria e fare un match dei piatti presenti a sistema
    const size = React.useContext(ResponsiveContext);
    const [iconaPiatto, setIconaPiatto] = React.useState(null); //Utilizzata per inserire le icone/immagini dei piatti
    const db = getFirestore();
    const storage=getStorage();
    
    /**
     * Leggo email e password da sessionStorage, se le trova tenta di autentificarsi
     * se fallisce riporta alla schermata di log-in
     */
    React.useEffect(()=>{
        var email=sessionStorage.getItem('emailAmministratore');
        var password=sessionStorage.getItem('passwordAmministratore');
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            console.log("Log in avvenuto con successo");
        })
        .catch((error) => {
            console.log("Errore nel login "+error);
            UserNotLoggedIn();
        });
        setEmail(email);
    },[email])

    React.useEffect(()=>{
        if(rendering){
            setRendering(false);
            var email=sessionStorage.getItem('emailAmministratore');
             /*
            * Funzione che scarica da firestore tutti le categorie del locale
            */
            let elencoCategorieAppoggio=[];
            const fetchCategorie=async()=>{
                var q = query(collection(db, email),where("tipo","==","Categoria"));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((item)=>{
                    elencoCategorieAppoggio.push(item);
                });
                elencoCategorieAppoggio.sort(function(a,b){
                    return a.data().indice - b.data().indice;
                  })
                setElencoCategorie(elencoCategorieAppoggio);
            }
            /*
            * Funzione che scarica da firestore tutti i piatti del locale
            */
            let elencoPiattiAppoggio=[];
            const fetchPiatti=async()=>{
                var q = query(collection(db, email),where("tipo","==","Piatto"));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((item)=>{
                    elencoPiattiAppoggio.push(item);
                })
                if(sessionStorage.getItem("ordine")!="alfabetico"){
                    elencoPiattiAppoggio.sort((a, b) => +(a.data().categoria > b.data().categoria ) || -(a.data().categoria < b.data().categoria));
                    elencoPiattiAppoggio.sort(function(a,b){
                        if(a.data().categoria===b.data().categoria){
                            return a.data().indice - b.data().indice;
                        }
                    })
                }else{
                    elencoPiattiAppoggio.sort((a, b) => +(a.data().nome > b.data().nome) || -(a.data().nome < b.data().nome));
                }
               
                setElencoPiatti(elencoPiattiAppoggio);
            }
            const recuperaLogo=()=>{
                var nomeDocumento="logo_"+email;
                const gsReference = ref(storage, 'loghi/'+nomeDocumento);    
                getDownloadURL(gsReference).then((url) => {
                  setUrlImmagine(url);
                });    
              }
            recuperaLogo(); 
            fetchCategorie();
            fetchPiatti();
        }  
    },[db,rendering,storage])

   
   const UserNotLoggedIn=()=>{
    alert("Non hai effettuato il log-in")
    props.history.push({ 
      pathname: '/',
    });
  }

    const OnEscLayer=()=>{
        setShowLayer(false);
        CancellaCampi();
        setAggiorna(false);
    }

    /**
     * Funzione che cancella resetta tutti i campi
     * di testo del layer
     */
    const CancellaCampi=()=>{
        setDropDownCategoria("");
        setDescrizione("");
        setDropDownTipo("");
        SetPrezzo("€");
        SetNome("");
        setIndiceCategoria("");
    }



/**
 * Funzione che aggiunge gli elementi a firestore in base alla selezione
 * del dropDown Menu presente nel layer
 * La funzione considera come facoltativo il campo "descrizione" di un nuovo piatto
 * Vengono considerati obbligatori nome e indiceCategoria per le categorie e
 * nome,categoria e prezzo per i patti 
 */
    const Aggiungi=async()=>{
        try{
            if(dropDownTipo!=="Piatto"){  
                if(nome!=="" && (indiceCategoria!=="" && /^\d+$/.test(indiceCategoria))){ //Regex per verificare che indiceCategoria sia un numero
                    await addDoc(collection(db, email), {
                        tipo: dropDownTipo,
                        indice:indiceCategoria,
                        nome: nome,
                       }); 
                       alert("Categoria aggiunta con successo");
                       OnEscLayer();
                       setRendering(true); //Segnalo che devo renderizzare
                }else{
                    alert("Non hai compilato correttamente tutti i campi obbligatori");
                } 
            }else{
                if(dropDownCategoria!=="" && nome!=="" && prezzo!=="" && /^\d+$/.test(indiceCategoria)){
                await addDoc(collection(db, email), {
                        categoria:dropDownCategoria,
                        tipo: dropDownTipo,
                        nome: nome,
                        descrizione:descrizione,
                        prezzo:prezzo.replace("€",""),
                        indice:indiceCategoria,
                        immagine: iconaPiatto
                        }); 
                       alert("Piatto aggiunto con successo");
                       OnEscLayer();
                       setRendering(true); //Segnalo che devo renderizzare
                }else{
                    alert("Non hai compilato correttamente tutti i campi obbligatori");
                }      
            }
            CancellaCampi();
        }catch(e){
            console.log("errore nella creazione "+e);
        }
    }


/**
 * Funzione che aggiorna i dati in firestore
 * Aggiorna i dati in base alla pagina selezionata dalla siderBar 
 */
    const Aggiorna=async()=>{
        if(pagina==="Categoria"){
            if(nome!=="" && indiceCategoria!=="" && /^\d+$/.test(indiceCategoria)){ //Regex per verificare che indiceCategoria sia un numero
            const Ref = doc(db, email, elencoCategorie[indexAggiorna].id);
            await updateDoc(Ref, {
                tipo: dropDownTipo,
                indice:indiceCategoria,
                nome: nome,
            });
            var q = query(collection(db, email),where("categoria","==",nomeCategoriaApp));
            const querySnapshot = await getDocs(q);
            const elencoDoc=[];
            querySnapshot.forEach((item)=>{
                elencoDoc.push(item);  
            })
            for(var i=0;i<elencoDoc.length;i++){
                const Ref = doc(db, email,elencoDoc[i].id);
                console.log("doc trovato");
                await updateDoc(Ref, {
                    categoria:nome,
                });
            }
            alert("Categoria aggiornata con successo");
            setRendering(true); //Segnalo che devo renderizzare
            }else{
                alert("Non hai compilato correttamente tutti i campi obbligatori");
            }
        }else if(pagina==="Piatto"){
            if(dropDownCategoria!=="" && nome!=="" && prezzo!=="" && /^\d+$/.test(indiceCategoria) ){
            const Ref = doc(db, email, elencoPiatti[indexAggiorna].id);
            await updateDoc(Ref, {
                categoria:dropDownCategoria,
                tipo: dropDownTipo,
                nome: nome,
                descrizione:descrizione,
                prezzo:prezzo.replace("€",""),
                indice:indiceCategoria,
                immagine: iconaPiatto
            });
            alert("Piatto aggiornato con successo");
            setRendering(true); //Segnalo che devo renderizzare
            }else{
                alert("Non hai compilato correttamente tutti i campi obbligatori");
            }
        }
        setAggiorna(false); //Non sto aggiornando nessun elemento
        setIndexAggiorna(); //Siccome non aggiorno nulla l'indice è nullo  
        CancellaCampi();
        OnEscLayer();
    }

    async function aggiornaIndice(indiceElncoOrd){
        if(pagina==="Categoria"){
            const Ref = doc(db, email, elencoCategorie[indiceElncoOrd].id);
            await updateDoc(Ref, {
                indice:indiceElncoOrd,
            });
        }else if(pagina==="Piatto"){
            const Ref = doc(db, email, elencoPiatti[indiceElncoOrd].id);
            await updateDoc(Ref, {
                categoria:dropDownCategoria,
                tipo: dropDownTipo,
                nome: nome,
                descrizione:descrizione,
                prezzo:prezzo.replace("€",""),
                immagine: iconaPiatto
            });
        }
    }


    /**
     * 
     * @param {*} index: indice dell'elemento cliccato
     * 
     * Prepara i campi del layer con le infromazioni dell'elemento cliccato e
     * mostra il layer
     */

    const OnClickElement=(index)=>{
        var elemento;
        if(pagina==="Categoria"){
          elemento=elencoCategorie[index].data();
          setNomeCategoriaApp(elemento.nome);
          setDropDownTipo(elemento.tipo);
          SetNome(elemento.nome);
          setIndiceCategoria(elemento.indice);
        }else if(pagina==="Piatto"){
            elemento=elencoPiatti[index].data();
            setDropDownCategoria(elemento.categoria);
            SetNome(elemento.nome);
            setDropDownTipo(elemento.tipo);
            setDescrizione(elemento.descrizione);
            SetPrezzo(elemento.prezzo+"€");
            setIndiceCategoria(elemento.indice);
            setIconaPiatto(elemento.immagine === null || elemento.immagine === undefined ? null : elemento.immagine)
        }
        setAggiorna(true); //Segnalo che sto per aggiornare un elemento
        setIndexAggiorna(index);
        setShowLayer(true);
    }

    /**
     * Elimina l'elemento selezionato
     */
    const DeleteElement=async()=>{
        if(pagina==="Categoria"){
            await deleteDoc(doc(db, email, elencoCategorie[indexAggiorna].id));
            alert("Categoria eliminata con successo");
            setRendering(true); //Segnalo che devo renderizzare
        }else if(pagina==="Piatto"){
            await deleteDoc(doc(db, email, elencoPiatti[indexAggiorna].id));
            alert("Piatto eliminato con successo");
            setRendering(true); //Segnalo che devo renderizzare
        }
        setAggiorna(false);
        setIndexAggiorna();
        CancellaCampi();
        OnEscLayer();
    }

    return(
        <Grommet>    
            <Box direction="row" >
                <SideBarPage setShowLayer={setShowLayer} url={urlImmagine} setPagina={setPagina} size={size} elencoPiatti={elencoPiatti} setElencoPiatti={setElencoPiatti} pagina={pagina} />
                {(pagina==="Categoria")?
                <Lista pagina={pagina} size={size} elenco={elencoCategorie} onClick={OnClickElement} set={setElencoCategorie} aggiornaIndice={aggiornaIndice}/>
                :(pagina==="Piatto" )?
                <Lista  pagina={pagina} size={size} elenco={elencoPiatti} onClick={OnClickElement} set={setElencoPiatti} aggiornaIndice={aggiornaIndice}/>
                :(pagina==="Logo")?
                <LayerLogo setRendering={setRendering} email={email} setPagina={setPagina}/>
                :
                <LayerQrCode email={email} setPagina={setPagina}/>
            }
            </Box>
            
           {/**LAYER PER AGGIUNGERE O AGGIORNARE ELEMENTI */} 
            {showLayer?
              <Layer full="vertical">
              <Box pad="medium" align="center" direction="row" fill="vertical" overflow="auto">
                <Box gap="small" style={{marginTop: (!aggiorna)? "45%": "20%"}}>
                   <h3>{(!aggiorna)?"Aggiungi elemento":"Aggiorna elemento"}</h3>
                   {/* TIPO */ }                
                   {!aggiorna?
                   <Box direction="column" gap="medium" >
                               <Text style={{marginRight:'10px',marginTop:'-3px', fontSize:'18px'}}>Tipologia:* </Text>
                              <Select
                                   options={elencoTipologie}
                                   value={dropDownTipo}
                                   onChange={( option) =>setDropDownTipo(option.value)}/> 
                             </Box>
                    :
                    <Box/>
                    }
                  {/*DROPDOWN ELENCO CATEGORIE (SOLO PER PIATTI)*/}
                  {dropDownTipo==="Piatto"?
                  <Box direction="column" gap="medium" >
                      <Text style={{marginRight:'10px',marginTop:'-3px', fontSize:'18px'}}>Categoria:* </Text>
                          <Select
                              options={elencoCategorie.map((item)=>item.data().nome)}
                              value={dropDownCategoria}
                              onChange={( option) =>setDropDownCategoria(option.value)}/> 
                      </Box>
                      :
                      <Box/>}
                          
                          {/* NOME*/ }
                          {(dropDownTipo!=="")?
                           <Box>
                           <FormField>
                           <Box direction="column" >
                               <Text style={{marginRight:'10px',marginTop:'-3px', fontSize:'18px'}}>Nome {dropDownTipo}:*</Text>
                               <TextInput
                                 plain
                                 value={nome}
                                 onChange={(event)=>SetNome(event.target.value)}/>
                             </Box>
                             </FormField>      
                           <FormField>
                           <Box direction="column" >
                               <Text style={{marginRight:'10px',marginTop:'-3px', fontSize:'18px'}}>Indice nel menù:*</Text>
                               <TextInput
                                 plain
                                 value={indiceCategoria}
                                 onChange={(event)=>setIndiceCategoria(event.target.value)}/>
                             </Box>
                             </FormField>
                             </Box>
                             :null}
                           {/*DESCRIZIONE (SOLO PER PIATTI)*/ }
                         {dropDownTipo==="Piatto"?    
                           <Box gap="medium">     
                          <FormField>
                            <Box direction="column" gap="small" >
                              <Text style={{marginRight:'10px',marginTop:'-3px', fontSize:'18px'}}>Prezzo:*</Text>
                              <TextInput
                                plain
                                value={prezzo}
                                onChange={(event)=>SetPrezzo(event.target.value)}/>
                            </Box>
                        </FormField>
                            <Box direction="column" gap="medium" width="600px">
                                <Text style={{marginRight:'10px',marginTop:'-3px', fontSize:'18px'}}>Icona/Immagine:</Text>
                                <FileInput
                                    multiple={{
                                    max: 1,
                                    }}
                                        name="file"
                                        onChange={event => {
                                        const fileList = event.target.files;  
                                        try{ 
                                            const filePicked=fileList[0];
                                            const storage = getStorage(); //Inizializzo firebase storage
                                            let fileName; 
                                            fileName="icona_"+nome; //Creo il nome del file   
                                            const image=ref(storage,"immagini/"+fileName); //Creo un riferimento come richiesto da firebase
                                            uploadBytes(image, filePicked).then((res) => { //Uso la funzione uploadBytes per caricarlo
                                                console.log('File caricato => ', res);
                                                var nomeDocumento = res.metadata.fullPath;
                                                const gsReference = ref(storage, nomeDocumento);    
                                                getDownloadURL(gsReference).then((url) => {
                                                    setIconaPiatto(url);
                                                    console.log("URL icona/immagine => ", url);
                                                });
                                            }); 
                                        } catch(e){
                                            console.log("Errore nel caricamento");
                                        }
                                }}/>
                            </Box>
                        <Box direction="column" gap="medium" width="600px">
                               <Text style={{marginRight:'10px',marginTop:'-3px', fontSize:'18px'}}>Descrizione:</Text>
                               <TextArea   
                                  size="small"
                                  resize={false}
                                  placeholder="Scrivi ingredienti o descrizione"
                                  value={descrizione}
                                  onChange={event => setDescrizione(event.target.value)}
                                  />
                             </Box>
                        </Box>
                          :
                          <Box/>    
                          } 
                    <Box direction="row" gap="medium" pad="medium">
                        <Button label="Chiudi" onClick={OnEscLayer} style={{width:'130px',fontSize:'15px',background:'red',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderColor:'red'}} /> 
                        {aggiorna?
                        <Button label="Rimuovi" onClick={DeleteElement} style={{width:'130px',fontSize:'15px',background:'red',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderColor:'red'}} /> 
                            :
                            <Box/>    
                        }
                        <Button label={(!aggiorna)?"Aggiungi":"Aggiorna"} onClick={(!aggiorna)?Aggiungi:Aggiorna} style={{width:'130px',fontSize:'15px',background:'#009245',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderColor:'#009245'}} /> 
                    </Box>       
                </Box>
            </Box>
           </Layer>
           :
           <Box/>   
        }
        </Grommet>
    );
}

/**
 * Restitutisce la lista con gli elementi in base alla pagina selezionata
 */
const Lista=(props)=>{
  
    return(
        <Box height="large" overflow="scroll" background="light-2"  width="xxlarge">
            <List     
            onClickItem={(event) => {
                props.onClick(event.index);
            }}
            primaryKey={(item) => (            
                (item!==undefined)? 
                <Text size="large" weight="bold" color="black">
                {item.data().nome}
                </Text>
                :
                null
            )}
            secondaryKey={(item) => (
                (item!==undefined)?
                <Text size="medium" color="dark-4">
                    {(props.pagina==="Piatto"?item.data().categoria+" "+item.data().indice : " ")}
                </Text>
                :
                null
        )}
            data={props.elenco}
            />
        </Box> 
    )
}

export default DashBoard;