import {Card,CardHeader,Box,Button,TextInput, Grommet,Header,Heading} from "grommet";
import {Login,Trash,View,Hide} from "grommet-icons";
import React from "react";
import { getAuth, signInWithEmailAndPassword} from "firebase/auth";

const colore="#605555";

const LoginPage=(props)=>{

    const [emailValue, setEmailValue] = React.useState(""); 
    const [passwordValue, setPasswordValue] = React.useState("");    
    const [visiblePassword,setVisiblePassword]=React.useState(false);   //Utilizzata per la possibilità di nascondere o mostrare il testo della pwd

   

    //Aggiorna i texInput
  const onChangeEmail = event => {
    setEmailValue(event.target.value);
  };
  const onChangePassword = event => {
    setPasswordValue(event.target.value);
  };

  //Cancella i testi nei textInput
  const Reset=()=>{
      setEmailValue("");
      setPasswordValue("");
  }

  
  //Funzione di log-in, se ha successo reindirizza alla home
  const Login=()=>{
    const auth = getAuth();
    signInWithEmailAndPassword(auth, emailValue, passwordValue)
  .then((userCredential) => {
    // Log in avvenuto con successo
    console.log("Success");
    sessionStorage.setItem('emailAmministratore', emailValue);
    sessionStorage.setItem('passwordAmministratore', passwordValue);
    props.history.push({ 
      pathname: '/dashboard',
    })
  })
  .catch((error) => {
    //Log-in errato, restituisco un errore
    console.log("Error");
    alert("Credenziali errate");
  });
}

    return (
      <Grommet>
        <HeaderPage/>
        <Box pad="large" background={colore} align="center" width="xxlarge" height="large">
          <Card pad="small" background="white" >
              <CardHeader pad="medium">Login</CardHeader>
              <Box pad="medium" direction="column" gap="medium" >
                <TextInputComponent textValue={emailValue} onChangeText={onChangeEmail} placeholder={"Inserisci email"}/>
                <PasswordTextInput textPassword={passwordValue} visiblePassword={visiblePassword} onChangePasswordText={onChangePassword} setVisiblePassword={setVisiblePassword} placeholder={"Inserisci password"}/>
                <BoxButton resetMethod={Reset} loginMethod={Login} />
              </Box>
          </Card>
        </Box>
      </Grommet>
    );
}

const ButtonComponent=(props)=>{

  return (
      <Button color={props.color} pad="small" icon={props.icon} label={props.label} onClick={props.onClick} primary />   
  )
}

const PasswordTextInput=(props)=>{
  return(
   <Box   direction="row" align="center" round="small" border>
       <TextInput
           plain
           placeholder={props.placeholder}
           type={props.visiblePassword ? 'text' : 'password'}
           value={props.textPassword}
           onChange={event => props.onChangePasswordText(event)}/>
       <Button
           icon={props.visiblePassword ? <View size="medium" /> : <Hide size="medium" />}  
           onClick={() => props.setVisiblePassword(!props.visiblePassword)}/> 
   </Box>
      )  
}

const TextInputComponent=(props)=>{
  return (
      <Box align="center" round="small" border>
      <TextInput
          plain
          placeholder={props.placeholder}
          value={props.textValue}
          onChange={props.onChangeText}/>
      </Box>
  )
  }


const BoxButton=(props)=>{

    return (
        <Box pad="medium" gap="medium" direction="row">
            <ButtonComponent color={colore} pad="small" icon={<Login />} label="Login" onClick={() => {props.loginMethod()}} primary />
            <ButtonComponent color={colore} pad="small" icon={<Trash />} label="Reset" onClick={() => {props.resetMethod()}} primary />
        </Box>
    )
}

const HeaderPage=()=>{
  return (
    <Header  height="70px" background={colore}  elevation="accent-4">
        <Box width="xxlarge" align="center">
          <Heading level="2" alignSelf="center" margin="small" size="small" style={{textTransform:'uppercase'}}>
                  Log-in
          </Heading>
         </Box>
  </Header>
  )
}

export default LoginPage;